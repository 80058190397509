import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-billings-montana.png'
import image0 from "../../images/cities/scale-model-of-zoomontana-in-billings-montana.png"
import image1 from "../../images/cities/scale-model-of-moss-mansion-museum-in-billings-montana.png"
import image2 from "../../images/cities/scale-model-of-western-heritage-center-in-billings-montana.png"
import image3 from "../../images/cities/scale-model-of-yellowstone-county-museum-in-billings-montana.png"
import image4 from "../../images/cities/scale-model-of-yellowstone-art-museum-in-billings-montana.png"
import image5 from "../../images/cities/scale-model-of-wise-wonders-children's-museum-in-billings-montana.png"
import image6 from "../../images/cities/scale-model-of-group-destination-planners-llc-in-billings-montana.png"
import image7 from "../../images/cities/scale-model-of-billings-van-&-shuttle-in-billings-montana.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Billings'
            state='Montana'
            image={image}
            lat='45.7832856'
            lon='-108.5006904'
            attractions={ [{"name": "ZooMontana", "vicinity": "2100 Shiloh Road, Billings", "types": ["zoo", "point_of_interest", "establishment"], "lat": 45.73225129999999, "lng": -108.62106970000002}, {"name": "Moss Mansion Museum", "vicinity": "914 Division St, Billings", "types": ["museum", "store", "point_of_interest", "establishment"], "lat": 45.77965, "lng": -108.51602400000002}, {"name": "Western Heritage Center", "vicinity": "2822 Montana Ave, Billings", "types": ["museum", "point_of_interest", "establishment"], "lat": 45.780658, "lng": -108.50551999999999}, {"name": "Yellowstone County Museum", "vicinity": "1950 Terminal Cir, Billings", "types": ["museum", "point_of_interest", "establishment"], "lat": 45.80242, "lng": -108.5376857}, {"name": "Yellowstone Art Museum", "vicinity": "401 N 27th St, Billings", "types": ["museum", "point_of_interest", "establishment"], "lat": 45.7858403, "lng": -108.5070829}, {"name": "Wise Wonders Children's Museum", "vicinity": "110 N 29th St, Billings", "types": ["museum", "point_of_interest", "establishment"], "lat": 45.7817433, "lng": -108.50748729999998}, {"name": "Group Destination Planners Llc", "vicinity": "2501 Montana Ave # 17, Billings", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 45.7829237, "lng": -108.50141730000001}, {"name": "Billings Van & Shuttle", "vicinity": "314 N 20th St, Billings", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 45.7887801, "lng": -108.49851260000003}] }
            attractionImages={ {"ZooMontana":image0,"Moss Mansion Museum":image1,"Western Heritage Center":image2,"Yellowstone County Museum":image3,"Yellowstone Art Museum":image4,"Wise Wonders Children's Museum":image5,"Group Destination Planners Llc":image6,"Billings Van & Shuttle":image7,} }
       />);
  }
}